/**
* get random number.
* @param {number} min - min number.
* @param {number} max - max number.
*/

.loader-root {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e1e1e;
}

.root {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23faf9f9' fill-opacity='0.19' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  height: 100vh;
  width: 100vw;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #ebebeb;
  position: relative;
  overflow: hidden;
  font-size: 100%;
  text-align: center;
}

.criterion {
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0;
  width: 0;
  transform: translate(-50px, -50px);
}
.background {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 0;
  -webkit-animation: background-animation 2s ease-in-out 4s 1 normal forwards;
  animation: background-animation 2s ease-in-out 4s 1 normal forwards;
}
.background0 {
  left: 0%;
  height: 100vh;
  background-color: #adb5bd;
}
.background1 {
  left: 12.5%;
  height: 100vh;
  background-color: #1b4965;
}
.background2 {
  left: 25%;
  height: 100vh;
  background-color: #76eb47;
}
.background3 {
  left: 37.5%;
  height: 100vh;
  background-color: #47eba5;
}
.background4 {
  left: 50%;
  height: 100vh;
  background-color: #47a5eb;
}
.background5 {
  left: 62.5%;
  height: 100vh;
  background-color: #7647eb;
}
.background6 {
  left: 75%;
  height: 100vh;
  background-color: #eb47d3;
}
.background7 {
  left: 87.5%;
  height: 100vh;
  background-color: #adb5bd;
}
/*
* text
*/
.text {
  position: absolute;
  width: 100px;
  line-height: 100px;
  opacity: 0;
  overflow: hidden;
}
.text::after {
  z-index: -1;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100px;
}
.text0 {
  left: -300px;
  top: 0;
  -webkit-animation: text-animation0 1s ease-in-out 1s 1 normal forwards,
    text2-animation0 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation0 1s ease-in-out 1s 1 normal forwards,
    text2-animation0 2s ease-in-out 5s 1 normal forwards;
}
.text0::after {
  -webkit-animation: text-after-animation0 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation0 2s ease-in-out 3s 1 normal forwards;
}
.text1 {
  left: -200px;
  top: 0;
  -webkit-animation: text-animation1 1s ease-in-out 1.2s 1 normal forwards,
    text2-animation1 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation1 1s ease-in-out 1.2s 1 normal forwards,
    text2-animation1 2s ease-in-out 5s 1 normal forwards;
}
.text1::after {
  -webkit-animation: text-after-animation1 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation1 2s ease-in-out 3s 1 normal forwards;
}
.text2 {
  left: -100px;
  top: 0;
  -webkit-animation: text-animation2 1s ease-in-out 1.4s 1 normal forwards,
    text2-animation2 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation2 1s ease-in-out 1.4s 1 normal forwards,
    text2-animation2 2s ease-in-out 5s 1 normal forwards;
}
.text2::after {
  -webkit-animation: text-after-animation2 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation2 2s ease-in-out 3s 1 normal forwards;
}
.text3 {
  left: 0px;
  top: 0;
  -webkit-animation: text-animation3 1s ease-in-out 1.6s 1 normal forwards,
    text2-animation3 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation3 1s ease-in-out 1.6s 1 normal forwards,
    text2-animation3 2s ease-in-out 5s 1 normal forwards;
}
.text3::after {
  -webkit-animation: text-after-animation3 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation3 2s ease-in-out 3s 1 normal forwards;
}
.text4 {
  left: 100px;
  top: 0;
  -webkit-animation: text-animation4 1s ease-in-out 1.8s 1 normal forwards,
    text2-animation4 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation4 1s ease-in-out 1.8s 1 normal forwards,
    text2-animation4 2s ease-in-out 5s 1 normal forwards;
}
.text4::after {
  -webkit-animation: text-after-animation4 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation4 2s ease-in-out 3s 1 normal forwards;
}
.text5 {
  left: 200px;
  top: 0;
  -webkit-animation: text-animation5 1s ease-in-out 2s 1 normal forwards,
    text2-animation5 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation5 1s ease-in-out 2s 1 normal forwards,
    text2-animation5 2s ease-in-out 5s 1 normal forwards;
}
.text5::after {
  -webkit-animation: text-after-animation5 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation5 2s ease-in-out 3s 1 normal forwards;
}
.text6 {
  left: 300px;
  top: 0;
  -webkit-animation: text-animation6 1s ease-in-out 2.2s 1 normal forwards,
    text2-animation6 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation6 1s ease-in-out 2.2s 1 normal forwards,
    text2-animation6 2s ease-in-out 5s 1 normal forwards;
}
.text6::after {
  -webkit-animation: text-after-animation6 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation6 2s ease-in-out 3s 1 normal forwards;
}
.text7 {
  left: 400px;
  top: 0;
  -webkit-animation: text-animation7 1s ease-in-out 2.4s 1 normal forwards,
    text2-animation7 2s ease-in-out 5s 1 normal forwards;
  animation: text-animation7 1s ease-in-out 2.4s 1 normal forwards,
    text2-animation7 2s ease-in-out 5s 1 normal forwards;
}
.text7::after {
  -webkit-animation: text-after-animation7 2s ease-in-out 3s 1 normal forwards;
  animation: text-after-animation7 2s ease-in-out 3s 1 normal forwards;
}
@-webkit-keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation0 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation1 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation2 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation3 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation4 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation5 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation6 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes text-animation7 {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(3, 3);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
@-webkit-keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #adb5bd;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation0 {
  0% {
    width: 0px;
    background-color: #adb5bd;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #1b4965;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation1 {
  0% {
    width: 0px;
    background-color: #1b4965;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #76eb47;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation2 {
  0% {
    width: 0px;
    background-color: #76eb47;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #47eba5;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation3 {
  0% {
    width: 0px;
    background-color: #47eba5;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #47a5eb;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation4 {
  0% {
    width: 0px;
    background-color: #47a5eb;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #7647eb;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation5 {
  0% {
    width: 0px;
    background-color: #7647eb;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #eb47d3;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation6 {
  0% {
    width: 0px;
    background-color: #eb47d3;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #adb5bd;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text-after-animation7 {
  0% {
    width: 0px;
    background-color: #adb5bd;
    opacity: 1;
  }
  50% {
    width: 100px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation0 {
  0% {
    left: -300px;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: -200px;
    opacity: 0;
  }
}
@keyframes text2-animation0 {
  0% {
    left: -300px;
    opacity: 1;
  }
  50% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: -200px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation1 {
  0% {
    left: -200px;
    opacity: 1;
  }
  50% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: -100px;
    opacity: 0;
  }
}
@keyframes text2-animation1 {
  0% {
    left: -200px;
    opacity: 1;
  }
  50% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: -100px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation2 {
  0% {
    left: -100px;
    opacity: 1;
  }
  50% {
    left: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 0;
  }
}
@keyframes text2-animation2 {
  0% {
    left: -100px;
    opacity: 1;
  }
  50% {
    left: 0px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation3 {
  0% {
    left: 0px;
    opacity: 1;
  }
  50% {
    left: 100px;
    opacity: 0;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@keyframes text2-animation3 {
  0% {
    left: 0px;
    opacity: 1;
  }
  50% {
    left: 100px;
    opacity: 0;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation4 {
  0% {
    left: 100px;
    opacity: 1;
  }
  50% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 200px;
    opacity: 0;
  }
}
@keyframes text2-animation4 {
  0% {
    left: 100px;
    opacity: 1;
  }
  50% {
    left: 200px;
    opacity: 0;
  }
  100% {
    left: 200px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation5 {
  0% {
    left: 200px;
    opacity: 1;
  }
  50% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 300px;
    opacity: 0;
  }
}
@keyframes text2-animation5 {
  0% {
    left: 200px;
    opacity: 1;
  }
  50% {
    left: 300px;
    opacity: 0;
  }
  100% {
    left: 300px;
    opacity: 0;
  }
}
@-webkit-keyframes text2-animation6 {
  0% {
    left: 300px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }
  50% {
    left: 400px;
    opacity: 1;
    transform: scale(1, 1);
  }
  65% {
    top: 0;
    transform: scale(1, 1);
  }
  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }
  75% {
    left: 400px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }
  85% {
    left: 400px;
  }
  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}
@keyframes text2-animation6 {
  0% {
    left: 300px;
    opacity: 1;
    top: 0;
    transform: scale(1, 1);
  }
  50% {
    left: 400px;
    opacity: 1;
    transform: scale(1, 1);
  }
  65% {
    top: 0;
    transform: scale(1, 1);
  }
  70% {
    transform: scale(3, 3) rotate(90deg);
    top: -30px;
  }
  75% {
    left: 400px;
    top: 0;
    opacity: 1;
    transform: scale(2, 2) rotate(90deg);
  }
  85% {
    left: 400px;
  }
  100% {
    left: 1000px;
    opacity: 0;
    transform: scale(2, 2) rotate(90deg);
  }
}
@-webkit-keyframes text2-animation7 {
  0% {
    left: 400px;
    opacity: 1;
  }
  50% {
    left: 500px;
    opacity: 0;
  }
  100% {
    left: 500px;
    opacity: 0;
  }
}
@keyframes text2-animation7 {
  0% {
    left: 400px;
    opacity: 1;
  }
  50% {
    left: 500px;
    opacity: 0;
  }
  100% {
    left: 500px;
    opacity: 0;
  }
}
/*
* frame
*/
.frame {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0;
}
.frame0 {
  left: -300px;
  top: 0;
  -webkit-animation: frame-animation0 1s ease-in-out 0ms 1 normal forwards;
  animation: frame-animation0 1s ease-in-out 0ms 1 normal forwards;
  background-color: #adb5bd;
}
.frame1 {
  left: -200px;
  top: 0;
  -webkit-animation: frame-animation1 1s ease-in-out 200ms 1 normal forwards;
  animation: frame-animation1 1s ease-in-out 200ms 1 normal forwards;
  background-color: #1b4965;
}
.frame2 {
  left: -100px;
  top: 0;
  -webkit-animation: frame-animation2 1s ease-in-out 400ms 1 normal forwards;
  animation: frame-animation2 1s ease-in-out 400ms 1 normal forwards;
  background-color: #76eb47;
}
.frame3 {
  left: 0px;
  top: 0;
  -webkit-animation: frame-animation3 1s ease-in-out 600ms 1 normal forwards;
  animation: frame-animation3 1s ease-in-out 600ms 1 normal forwards;
  background-color: #47eba5;
}
.frame4 {
  left: 100px;
  top: 0;
  -webkit-animation: frame-animation4 1s ease-in-out 800ms 1 normal forwards;
  animation: frame-animation4 1s ease-in-out 800ms 1 normal forwards;
  background-color: #47a5eb;
}
.frame5 {
  left: 200px;
  top: 0;
  -webkit-animation: frame-animation5 1s ease-in-out 1000ms 1 normal forwards;
  animation: frame-animation5 1s ease-in-out 1000ms 1 normal forwards;
  background-color: #7647eb;
}
.frame6 {
  left: 300px;
  top: 0;
  -webkit-animation: frame-animation6 1s ease-in-out 1200ms 1 normal forwards;
  animation: frame-animation6 1s ease-in-out 1200ms 1 normal forwards;
  background-color: #eb47d3;
}
.frame7 {
  left: 400px;
  top: 0;
  -webkit-animation: frame-animation7 1s ease-in-out 1400ms 1 normal forwards;
  animation: frame-animation7 1s ease-in-out 1400ms 1 normal forwards;
  background-color: #adb5bd;
}
@-webkit-keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation0 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation1 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation2 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation3 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation4 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation5 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation6 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@-webkit-keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes frame-animation7 {
  0% {
    transform: translateY(-1000px);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
/*
* particle
*/
.particle {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.particle00 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation00 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation00 1s ease-in-out 1s 1 normal forwards;
}
.particle01 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation01 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation01 1s ease-in-out 1s 1 normal forwards;
}
.particle02 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation02 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation02 1s ease-in-out 1s 1 normal forwards;
}
.particle03 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation03 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation03 1s ease-in-out 1s 1 normal forwards;
}
.particle04 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation04 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation04 1s ease-in-out 1s 1 normal forwards;
}
.particle05 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation05 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation05 1s ease-in-out 1s 1 normal forwards;
}
.particle06 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation06 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation06 1s ease-in-out 1s 1 normal forwards;
}
.particle07 {
  left: -300px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation07 1s ease-in-out 1s 1 normal forwards;
  animation: particle-animation07 1s ease-in-out 1s 1 normal forwards;
}
.particle10 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation10 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation10 1s ease-in-out 1.2s 1 normal forwards;
}
.particle11 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation11 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation11 1s ease-in-out 1.2s 1 normal forwards;
}
.particle12 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation12 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation12 1s ease-in-out 1.2s 1 normal forwards;
}
.particle13 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation13 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation13 1s ease-in-out 1.2s 1 normal forwards;
}
.particle14 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation14 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation14 1s ease-in-out 1.2s 1 normal forwards;
}
.particle15 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation15 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation15 1s ease-in-out 1.2s 1 normal forwards;
}
.particle16 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation16 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation16 1s ease-in-out 1.2s 1 normal forwards;
}
.particle17 {
  left: -200px;
  opacity: 0;
  background-color: #1b4965;
  -webkit-animation: particle-animation17 1s ease-in-out 1.2s 1 normal forwards;
  animation: particle-animation17 1s ease-in-out 1.2s 1 normal forwards;
}
.particle20 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation20 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation20 1s ease-in-out 1.4s 1 normal forwards;
}
.particle21 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation21 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation21 1s ease-in-out 1.4s 1 normal forwards;
}
.particle22 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation22 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation22 1s ease-in-out 1.4s 1 normal forwards;
}
.particle23 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation23 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation23 1s ease-in-out 1.4s 1 normal forwards;
}
.particle24 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation24 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation24 1s ease-in-out 1.4s 1 normal forwards;
}
.particle25 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation25 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation25 1s ease-in-out 1.4s 1 normal forwards;
}
.particle26 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation26 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation26 1s ease-in-out 1.4s 1 normal forwards;
}
.particle27 {
  left: -100px;
  opacity: 0;
  background-color: #76eb47;
  -webkit-animation: particle-animation27 1s ease-in-out 1.4s 1 normal forwards;
  animation: particle-animation27 1s ease-in-out 1.4s 1 normal forwards;
}
.particle30 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation30 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation30 1s ease-in-out 1.6s 1 normal forwards;
}
.particle31 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation31 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation31 1s ease-in-out 1.6s 1 normal forwards;
}
.particle32 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation32 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation32 1s ease-in-out 1.6s 1 normal forwards;
}
.particle33 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation33 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation33 1s ease-in-out 1.6s 1 normal forwards;
}
.particle34 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation34 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation34 1s ease-in-out 1.6s 1 normal forwards;
}
.particle35 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation35 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation35 1s ease-in-out 1.6s 1 normal forwards;
}
.particle36 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation36 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation36 1s ease-in-out 1.6s 1 normal forwards;
}
.particle37 {
  left: 0px;
  opacity: 0;
  background-color: #47eba5;
  -webkit-animation: particle-animation37 1s ease-in-out 1.6s 1 normal forwards;
  animation: particle-animation37 1s ease-in-out 1.6s 1 normal forwards;
}
.particle40 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation40 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation40 1s ease-in-out 1.8s 1 normal forwards;
}
.particle41 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation41 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation41 1s ease-in-out 1.8s 1 normal forwards;
}
.particle42 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation42 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation42 1s ease-in-out 1.8s 1 normal forwards;
}
.particle43 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation43 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation43 1s ease-in-out 1.8s 1 normal forwards;
}
.particle44 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation44 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation44 1s ease-in-out 1.8s 1 normal forwards;
}
.particle45 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation45 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation45 1s ease-in-out 1.8s 1 normal forwards;
}
.particle46 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation46 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation46 1s ease-in-out 1.8s 1 normal forwards;
}
.particle47 {
  left: 100px;
  opacity: 0;
  background-color: #47a5eb;
  -webkit-animation: particle-animation47 1s ease-in-out 1.8s 1 normal forwards;
  animation: particle-animation47 1s ease-in-out 1.8s 1 normal forwards;
}
.particle50 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation50 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation50 1s ease-in-out 2s 1 normal forwards;
}
.particle51 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation51 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation51 1s ease-in-out 2s 1 normal forwards;
}
.particle52 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation52 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation52 1s ease-in-out 2s 1 normal forwards;
}
.particle53 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation53 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation53 1s ease-in-out 2s 1 normal forwards;
}
.particle54 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation54 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation54 1s ease-in-out 2s 1 normal forwards;
}
.particle55 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation55 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation55 1s ease-in-out 2s 1 normal forwards;
}
.particle56 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation56 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation56 1s ease-in-out 2s 1 normal forwards;
}
.particle57 {
  left: 200px;
  opacity: 0;
  background-color: #7647eb;
  -webkit-animation: particle-animation57 1s ease-in-out 2s 1 normal forwards;
  animation: particle-animation57 1s ease-in-out 2s 1 normal forwards;
}
.particle60 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation60 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation60 1s ease-in-out 2.2s 1 normal forwards;
}
.particle61 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation61 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation61 1s ease-in-out 2.2s 1 normal forwards;
}
.particle62 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation62 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation62 1s ease-in-out 2.2s 1 normal forwards;
}
.particle63 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation63 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation63 1s ease-in-out 2.2s 1 normal forwards;
}
.particle64 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation64 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation64 1s ease-in-out 2.2s 1 normal forwards;
}
.particle65 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation65 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation65 1s ease-in-out 2.2s 1 normal forwards;
}
.particle66 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation66 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation66 1s ease-in-out 2.2s 1 normal forwards;
}
.particle67 {
  left: 300px;
  opacity: 0;
  background-color: #eb47d3;
  -webkit-animation: particle-animation67 1s ease-in-out 2.2s 1 normal forwards;
  animation: particle-animation67 1s ease-in-out 2.2s 1 normal forwards;
}
.particle70 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation70 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation70 1s ease-in-out 2.4s 1 normal forwards;
}
.particle71 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation71 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation71 1s ease-in-out 2.4s 1 normal forwards;
}
.particle72 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation72 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation72 1s ease-in-out 2.4s 1 normal forwards;
}
.particle73 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation73 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation73 1s ease-in-out 2.4s 1 normal forwards;
}
.particle74 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation74 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation74 1s ease-in-out 2.4s 1 normal forwards;
}
.particle75 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation75 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation75 1s ease-in-out 2.4s 1 normal forwards;
}
.particle76 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation76 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation76 1s ease-in-out 2.4s 1 normal forwards;
}
.particle77 {
  left: 400px;
  opacity: 0;
  background-color: #adb5bd;
  -webkit-animation: particle-animation77 1s ease-in-out 2.4s 1 normal forwards;
  animation: particle-animation77 1s ease-in-out 2.4s 1 normal forwards;
}
@-webkit-keyframes particle-animation00 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation00 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation01 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -237.6510198px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation01 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -237.6510198px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation02 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -322.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation02 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -322.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation03 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation03 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation04 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation04 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -390.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation05 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -322.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation05 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -322.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation06 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -237.6510198px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation06 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -237.6510198px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation07 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation07 {
  0% {
    left: -300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation10 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation10 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation11 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -137.6510198px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation11 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -137.6510198px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation12 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -222.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation12 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -222.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation13 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation13 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation14 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation14 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -290.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation15 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -222.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation15 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -222.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation16 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -137.6510198px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation16 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -137.6510198px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation17 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation17 {
  0% {
    left: -200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation20 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation20 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation21 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -37.65101979999999px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation21 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -37.65101979999999px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation22 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -122.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation22 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -122.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation23 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation23 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation24 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation24 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -190.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation25 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -122.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation25 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -122.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation26 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -37.65101979999999px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation26 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -37.65101979999999px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation27 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation27 {
  0% {
    left: -100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 0px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation30 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation30 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation31 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 62.34898020000001px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation31 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 62.34898020000001px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation32 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -22.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation32 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -22.2520934px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation33 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation33 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90.0968868px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation34 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation34 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -90.0968868px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation35 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -22.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation35 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: -22.2520934px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation36 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 62.34898020000001px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation36 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 62.34898020000001px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation37 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation37 {
  0% {
    left: 0px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 100px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation40 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation40 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation41 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 162.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation41 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 162.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation42 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 77.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation42 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 77.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation43 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 9.903113200000007px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation43 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 9.903113200000007px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation44 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 9.903113200000007px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation44 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 9.903113200000007px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation45 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 77.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation45 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 77.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation46 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 162.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation46 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 162.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation47 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation47 {
  0% {
    left: 100px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 200px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation50 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation50 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation51 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 262.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation51 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 262.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation52 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 177.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation52 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 177.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation53 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 109.9031132px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation53 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 109.9031132px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation54 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 109.9031132px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation54 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 109.9031132px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation55 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 177.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation55 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 177.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation56 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 262.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation56 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 262.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation57 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation57 {
  0% {
    left: 200px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 300px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation60 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 400px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation60 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 400px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation61 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 362.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation61 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 362.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation62 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 277.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation62 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 277.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation63 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 209.9031132px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation63 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 209.9031132px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation64 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 209.9031132px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation64 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 209.9031132px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation65 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 277.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation65 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 277.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation66 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 362.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation66 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 362.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation67 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 400px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation67 {
  0% {
    left: 300px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 400px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation70 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation70 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation71 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 462.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation71 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 462.3489802px;
    top: 78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation72 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 377.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation72 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 377.7479066px;
    top: 97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation73 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 309.9031132px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation73 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 309.9031132px;
    top: 43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation74 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 309.9031132px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation74 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 309.9031132px;
    top: -43.3883739px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation75 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 377.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation75 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 377.7479066px;
    top: -97.4927912px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation76 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 462.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation76 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 462.3489802px;
    top: -78.18314819999999px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes particle-animation77 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@keyframes particle-animation77 {
  0% {
    left: 400px;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
  }
  100% {
    left: 500px;
    top: 0px;
    opacity: 1;
    transform: scale(0, 0);
  }
}
@-webkit-keyframes background-animation {
  0% {
    width: 0;
  }
  50% {
    width: 12.5%;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 25%;
  }
}
@keyframes background-animation {
  0% {
    width: 0;
  }
  50% {
    width: 12.5%;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 25%;
  }
}
