body,
html {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #232728;
  /* background: #3e3e42; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #464c4f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.react-codemirror2 {
  height: 100%;
  width: 100%;
}

.CodeMirror-lines {
  margin: 12px;
}

.remote-caret {
  position: absolute;
  border-left: rgb(250, 129, 0);
  border-left-style: solid;
  border-left-width: 3px;
  height: 1.05em;
}
.remote-caret > div {
  position: relative;
  top: -1.05em;
  left: -0.15em;
  font-size: inherit;
  background-color: rgb(250, 129, 0);
  font-family: monospace;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  user-select: none;
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  z-index: 3;
}
